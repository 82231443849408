import React from "react";
import ProductTemplate from "./product";
// import { FiArrowRightCircle } from "react-icons/fi";
//import Image from "@components/common/CloudinaryImage";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
//import { Link } from "gatsby";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            <div className="container lg:px-0 py-20">
                {/** Qué es¿? */}
                <Title>Curs Online per la Guàrdia Urbana de Tarragona 2023</Title>
                <Text>
                    Enguany surten 19 places per l'accés a la Guàrdia Urbana de Tarragona.
                    <br />
                    <br />
                    Et presentem el curs més complet i específic per preparar l'accés a la Guàrdia
                    Urbana de Tarragona 2023
                    <br />
                    <br />
                    Amb tot el temari de les bases de 2023.
                    <br />
                    <br />
                    Són cursos fàcils d'estudiar, on tu decideixes com i quan avançar.
                    <br />
                    <br />
                    Al curs trobaràs tot el contingut necessari per posicionar-te entre els millors.
                    <br />
                    <br />
                    Un curs viu i en constant evolució.
                    <br />
                    <br />
                    Dins del curs trobaràs:
                    <br />
                    <br />
                    - Consistori
                    <br />
                    <br />
                    - El Municipi.
                    <br />
                    <br />
                    -Dades rellevants
                    <br />
                    <br />
                    - 2 exàmens oficials
                    <br />
                    <br />
                    -Simulacres mensuals on et posarem a prova
                    <br />
                    <br />
                    I els 40 temes exigits de tot el temari!
                    <br />
                    <br />
                    A més de cada tema tindreu test i idees força!!
                    <br />
                    <br />
                    Per un pagament únic de només 59,99 euros.
                    <br />
                    <br />
                    El curs estarà obert fins al dia de l'examen
                    <br />
                    <br />
                    Entra ara i avança al teu ritme!
                </Text>
            </div>
        </ProductTemplate>
    );
};
export default Product;
